import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

//UI
import {
  Header,
  FullContainer,
  ContactForm,
  Section,
  Container,
  Footer
} from './components/ui';

// Views
import Navigation from './views/Navigation';
import LandingPage from './views/Landing';
import CategoryPage from './views/Category';
import AdventurePage from './views/Adventure/';
import QueryPage from './views/Query';

import logo from './assets/img/ultimathule-logo.png';
import travelBadge from './assets/img/travel-agency.png';

import * as Analytics from './services/Analytics';
Analytics.initGA();
Analytics.initPixel();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: true,
    }
  }

  toggleNav = e => this.setState({ nav: !this.state.nav })

  render() {
    const { nav } = this.state;

    return (
      <div className="App">
        <Router>
          <div>
            <Header
              logo={logo}
              alt={"Ævintýraferðir í yfir 25 ár"}
              action={this.toggleNav.bind(this)}
            />
            {nav && <Navigation toggle={this.toggleNav.bind(this)} />}
            <Switch>
              <ScrollToTop>
                <Route exact path="/" component={LandingPage} />
                <Route path="/leit" component={QueryPage} />
                <Route path="/destination/:query" component={CategoryPage} />
                <Route path="/activity/:query" component={CategoryPage} />
                <Route path="/adventure/:id" component={AdventurePage} />
              </ScrollToTop>
            </Switch>
            <FullContainer primary>
              <ContactForm
                title="Langar þig að upplifa alvöru ævintýri?"
                subscript="Fylltu inn í reitina hér fyrir neðan og við munum svara þér eins fljótt og auðið er."
              />
            </FullContainer>
            <Container>
              <Section title={"Um Okkur"} center>
                <p>
                  Ultima Thule er ferðaskrifstofa sem selur ævintýraferðir um
                  allan heim. Við viljum kynna fyrir Íslendingum spennandi og
                  einstakar ferðir. Viljir þú sjá undur heimsins og prófa
                  eitthvað annað en að spranga um á sólarströnd þá ertu á réttum
                  stað.
                </p>
                <p>
                  Markmið Ultima Thule er að bjóða Íslendingum upp á spennandi
                  utanlandsferðir í háum gæðaflokki á sanngjörnu verði. Einnig
                  leggjum við okkur fram um að veita persónulega og góða
                  þjónustu. Við val á samstarfsaðilum okkar höfum við það að
                  leiðarljósi að þeir séu ábyrgir, bjóði skemmtilegar gæðaferðir
                  og að ferðast sé í litlum hópum.
                </p>
                <p>
                  Nafnið á ferðaskrifstofunni er sótt í sögu Íslands en talið er
                  að Ísland hafi verið nefnt Ultima Thule af landkönnuðinum
                  Pyþeas nokkrum öldum fyrir landnám víkinga. Ultima Thule var
                  lýst sem dularfullu landi við ystu mörk jarðarkringlunnar og
                  handan við það væri ekkert annað en ísilagið heimskautshaf.
                </p>
                <a
                  href="https://www.ferdamalastofa.is/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={travelBadge}
                    style={{ width: "100px" }}
                    alt="Vottorð Ferðaskrifstofu"
                  />
                </a>
              </Section>
            </Container>
            <Footer>
              <h4>ULTIMA THULE | FERD@ULTIMATHULE.IS</h4>
              <h1>567 8978</h1>
              <h4>SUNDABORG 9, 105 REYKJAVÍK</h4>
            </Footer>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
