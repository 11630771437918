import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Hero,
  FullContainer,
  SearchBar,
  Container,
  Section,
  Album,
  AdventureList,
  SearchButton,
} from "../components/ui";
import InteractiveMap from "../components/InteractiveMap/";

import heroImage from "../assets/img/hero-image.jpg";
import offerImage from "../assets/img/offer-image.png";

// Album covers
import walkingImage from "../assets/img/types/walking.jpg";
import cyclingImage from "../assets/img/types/cycling.jpg";
import safariImage from "../assets/img/types/safari.jpg";
import cultureImage from "../assets/img/types/culture.jpg";
import familyImage from "../assets/img/types/family.jpg";

import polarImage from "../assets/img/types/polar.jpg";
import winterImage from "../assets/img/types/winter.jpg";
import mixedImage from "../assets/img/types/mixed.jpg";
import skiingImage from "../assets/img/types/skiing.jpg";

// Services
import axios from "axios";
import { Adventures } from "../services/ultimathule-api";
import { Activities, Countries, Continents } from "../services/translations";
import * as Analytics from "../services/Analytics";

const buildURLQuery = (obj) =>
  Object.entries(obj)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popular: [],
      gbp: null,
      regions: [],
      activities: [],
    };
  }

  componentDidMount() {
    Analytics.logPageView();
  }
  // GALLAÐ
  addCountry = (country) => {
    const { regions } = this.state;
    this.setState({ regions: [...regions, country] });
  };
  removeCountry = (country) => {
    const { regions } = this.state;
    this.setState({ regions: regions.filter((region) => region !== country) });
  };
  // GALLAÐ
  addActivity = (activity) => {
    const { activities } = this.state;
    this.setState({ activities: [...activities, activity] });
  };
  removeActivity = (activity) => {
    const { activities } = this.state;
    this.setState({ activities: activities.filter((act) => act !== activity) });
  };

  componentWillMount() {
    Adventures.getBestSellers().then((res) => {
      this.setState({ popular: res.data.adventures });
    });

    axios.get("https://apis.is/currency/arion").then((res) => {
      this.setState({
        gbp: res.data.results.find((c) => c.shortName === "GBP").value,
      });
    });
  }

  render() {
    const { regions, activities, popular, gbp } = this.state;
    const query = {};
    regions.length && (query.regions = regions);
    activities.length && (query.activities = activities);
    return (
      <div>
        <Hero
          message={"Ævintýraferðir í yfir 25 ár"}
          image={heroImage}
          offer={
            new Date() <= new Date("March 10 2020 00:00:00 GMT-0000")
              ? offerImage
              : null
          }
        />
        <FullContainer flex>
          <SearchBar
            tag={"destination"}
            label={"Leitaðu að þínu ævintýri..."}
            placeholder={"Áfangastaður"}
            data={Countries.get().concat(Continents.get())}
            values={regions}
            select={this.addCountry.bind(this)}
            deSelect={this.removeCountry.bind(this)}
          />
          <SearchBar
            tag={"activity"}
            label={"Tegund ferðar"}
            placeholder={"Gönguferðir, hjólaferðir, dýralífs.."}
            data={Activities.get()}
            values={activities}
            select={this.addActivity.bind(this)}
            deSelect={this.removeActivity.bind(this)}
          />
          <SearchButton
            title={"Finna ferðir"}
            link={`/leit?${buildURLQuery(query)}`}
          >
            Leita
          </SearchButton>
        </FullContainer>
        <Container>
          <Section title={"Tegund ferðar"}>
            <Album
              adventures={[
                {
                  title: "Gönguskíðaferðir",
                  img: skiingImage,
                  link: "/leit?activities=cross-country+skiing,ski+racing",
                },
                {
                  title: "Gönguferðir",
                  img: walkingImage,
                  link: "/leit?activities=walking%20%26%20trekking",
                },
                {
                  title: "Hjólaferðir",
                  img: cyclingImage,
                  link: "/leit?activities=cycling",
                },
                {
                  title: "Dýralífsferðir",
                  img: safariImage,
                  link: "/leit?activities=wildlife+holidays",
                },
                {
                  title: "Menningarferðir",
                  img: cultureImage,
                  link: "/leit?activities=culture",
                },
                {
                  title: "Fjölskylduferðir",
                  img: familyImage,
                  link: "/leit?activities=family",
                },
                {
                  title: "Heimskautaferðir",
                  img: polarImage,
                  link: "/leit?activities=polar",
                },
                {
                  title: "Blandaðar ferðir",
                  img: mixedImage,
                  link: "/leit?activities=mixed-activity",
                },
                {
                  title: "Vetarferðir",
                  img: winterImage,
                  link: "/leit?activities=winter+holidays",
                },
              ]}
            />
          </Section>
          <Section title="Vinsælar ferðir">
            <br />
            <AdventureList adventures={popular} currency={gbp} />
          </Section>
          <Section title="Hvert langar þig helst fara?">
            <InteractiveMap />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {Continents.get().map((c, i) => (
                <Link
                  key={i}
                  to={`/leit?regions=${c.name}`}
                  style={{
                    textDecoration: "none",
                    color: "rgba(44, 165, 160, 0.49)",
                    textTransform: "capitalize",
                    margin: "0 10px",
                  }}
                >
                  <h3>{c.icelandicName}</h3>
                </Link>
              ))}
            </div>
          </Section>
        </Container>
      </div>
    );
  }
}

export default LandingPage;
